import React from "react"
import { Link } from "gatsby"
import Navbar from "../components/nav-bar"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPolicy = () => (
  <html class="dark">

    <div class="dark:bg-darkbackg overflow-y-auto w-screen h-full lg:h-screen">
      <Navbar />
      <SEO title="Privacy Policy" />
      <main class="flex-col flex justify-center mx-auto h-full px-8 pt-6 lg:px-32">
        {/* <div class="md:text-center lg:text-left lg:w-full"> */}
        <h4 class="text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white sm:text-4xl py-3">Privacy Policy</h4>
        <p class="font-sans text-gray-400 self-start text-base">Your privacy is important to us. It is Obsidian's policy to respect your privacy regarding any information we may collect from you through our app, Obsidian.</p>
        <p class="font-sans text-gray-400 self-start text-base">We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.</p>
        <p class="font-sans text-gray-400 self-start text-base">We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.</p>
        <p class="font-sans text-gray-400 self-start text-base">We don’t share any personally identifying information publicly or with third-parties, except when required to by law.</p>
        <p class="font-sans text-gray-400 self-start text-base">You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p>
        <p class="font-sans text-gray-400 self-start text-base">Your continued use of our app will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</p>
        <p class="font-sans text-gray-400 self-start text-base">This policy is effective as of 11 January 2021.</p>
        {/* </div> */}
      </main>
    </div>
  </html>
  // <Layout>
  //   <SEO title="Privacy Policy" />
  //   <h2>Privacy Policy</h2>
  //   <p>Your privacy is important to us. It is Obsidian's policy to respect your privacy regarding any information we may collect from you through our app, Obsidian.</p>
  //   <p>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.</p>
  //   <p>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.</p>
  //   <p>We don’t share any personally identifying information publicly or with third-parties, except when required to by law.</p>
  //   <p>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p>
  //   <p>Your continued use of our app will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</p>
  //   <p>This policy is effective as of 11 January 2021.</p>
  // </Layout>
)

export default PrivacyPolicy



{/* <html class="dark">
    <div class="bg-white dark:bg-darkbackg overflow-y-auto w-screen h-full lg:h-screen">
      <SEO title="Privacy Policy" />
      <main class="flex-col flex justify-center mx-auto h-full px-4 sm:px-6 pt-10 lg:pl-20">
      <div class="md:text-center lg:text-left lg:w-full">
        <h2 class="text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white sm:text-5xl md:text-6xl py-3">Privacy Policy</h2>
        <p class="font-sans mt-6 text-gray-400 self-start text-base">Your privacy is important to us. It is Obsidian's policy to respect your privacy regarding any information we may collect from you through our app, Obsidian.</p>
        <p class="font-sans mt-6 text-gray-400 self-start text-base">We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.</p>
        <p class="font-sans mt-6 text-gray-400 self-start text-base">We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.</p>
        <p class="font-sans mt-6 text-gray-400 self-start text-base">We don’t share any personally identifying information publicly or with third-parties, except when required to by law.</p>
        <p class="font-sans mt-6 text-gray-400 self-start text-base">Our app may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>
        <p class="font-sans mt-6 text-gray-400 self-start text-base">You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p>
        <p class="font-sans mt-6 text-gray-400 self-start text-base">Your continued use of our app will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</p>
        <p class="font-sans mt-6 text-gray-400 self-start text-base">This policy is effective as of 11 January 2021.</p>
        </div>
        </main>
    </div>
  </html> */}